
import { defineComponent, onMounted, ref } from "vue";
import Gender from "@/components/reusable/Gender.vue";
import RelationshipType from "@/components/reusable/RelationshipType.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";

export default defineComponent({
  name: "individual-client-relationship-information",
  components: {
    Gender,
    RelationshipType,
    ErrorMessage,
    Field,
    Form
  },
  props: ["publicId"],
  data() {
    return {
      displayDates: false,
      relationshipDetails: {
        address: "",
        avatar: "",
        currentAvatar: "",
        dateOfBirth: "",
        emailAddress: "",
        firstName: "",
        gender: "",
        identificationExpiryDate: "",
        identificationIssueDate: "",
        lastName: "",
        meansOfIdentification: "",
        middleName: "",
        phoneNumber: "",
        identificationNumber: "",
        publicId: "",
        relationship: ""
        // password: "",
      }
    };
  },
  setup() {
    const submitButton1 = ref<HTMLElement | null>(null);

    const relationshipDetailsValidator = Yup.object().shape({
      firstName: Yup.string().required().label("First name"),
      middleName: Yup.string().notRequired().label("Middle name"),
      lastName: Yup.string().required().label("Last name"),
      phoneNumber: Yup.number().required().label("Phone number"),
      dateOfBirth: Yup.date().required().label("Date of birth"),
      gender: Yup.mixed().required().label("Gender"),
      relationship: Yup.mixed().required().label("Relationship"),
      address: Yup.string().required().label("Address"),
      meansOfIdentification: Yup.mixed()
        .notRequired()
        .label("Means of identification"),
      identificationNumber: Yup.string()
        .notRequired()
        .label("Identification number"),
      identificationIssueDate: Yup.date()
        .notRequired()
        .label("Identification issue date"),
      identificationExpiryDate: Yup.date()
        .notRequired()
        .label("Identification expiry date")
      // password: Yup.string()
      //   .min(8)
      //   .required()
      //   .label("Password"),
    });

    onMounted(() => {
      setCurrentPageBreadcrumbs("Edit Relationship Information", [
        "Profile",
        "Relationship Information"
      ]);
    });

    return {
      submitButton1,
      relationshipDetailsValidator
    };
  },
  mounted() {
    // Get user relationship information on page load
    this.getRelationshipInformation();
  },
  created() {
    //Set page title
    document.title =
      "Edit Relationship Information | " + process.env.VUE_APP_TITLE;
  },
  methods: {
    getRelationshipInformation() {
      //Fetch client profile relationship infomation
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(variables.PROFILE_RELATION_INFORMATION, this.publicId)
          .then(({ data }) => {
            //Assign data to form fields
            this.relationshipDetails.currentAvatar = data.data.avatar;
            this.relationshipDetails.firstName = data.data.firstName;
            this.relationshipDetails.middleName = data.data.middleName;
            this.relationshipDetails.lastName = data.data.lastName;
            this.relationshipDetails.emailAddress = data.data.emailAddress;
            this.relationshipDetails.gender = data.data.gender.publicId;
            this.relationshipDetails.relationship =
              data.data.relationship.publicId;
            this.relationshipDetails.phoneNumber = data.data.phoneNumber;
            this.relationshipDetails.dateOfBirth = data.data.dateOfBirth;
            this.relationshipDetails.address = data.data.address;
            this.relationshipDetails.meansOfIdentification =
              data.data.meansOfIdentification;
            this.relationshipDetails.identificationExpiryDate =
              data.data.identificationExpiryDate;
            this.relationshipDetails.identificationIssueDate =
              data.data.identificationIssueDate;
            this.relationshipDetails.identificationNumber =
              data.data.identificationNumber;

            if (
              this.relationshipDetails.meansOfIdentification ==
                "Drivers License" ||
              this.relationshipDetails.meansOfIdentification ==
                "International Passport"
            ) {
              this.displayDates = true;
            }
          })
          .catch(function (error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    updateRelationshipInformation(values) {
      const submitButton1 = document.getElementById(
        "kt_account_profile_details_submit"
      ) as HTMLInputElement;

      if (submitButton1) {
        // Activate indicator
        submitButton1.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          submitButton1.removeAttribute("data-kt-indicator");

          // Send personal information update request
          if (JwtService.getToken()) {
            ApiService.setHeader();
            ApiService.patch(
              variables.PROFILE_RELATION_INFORMATION + "/" + this.publicId,
              values
            )
              .then((response) => {
                variables.toastAlert(response.data.message, "success");
                this.$router.push({ name: "profile" });
              })
              .catch(function (error) {
                variables.toastAlert(error.response.data.error, "error");
              });
          }
        }, 2000);
      }
    },
    handleImageUpload(event) {
      const selectedImage = event.target.files[0]; //get the first file

      const reader = new FileReader();
      reader.readAsDataURL(selectedImage);

      reader.onload = (e) => {
        this.relationshipDetails.avatar = reader.result as string;
      };

      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
    },
    acceptNumberOnly(event) {
      variables.acceptNumberOnly(event);
    },
    identificationIssueDateAndExpiry(event) {
      const value = event.target.value;
      value == "Drivers License" || value == "International Passport"
        ? (this.displayDates = true)
        : (this.displayDates = false);
      const dateIssued = document.getElementById(
        "date-of-issue"
      ) as HTMLInputElement;
      const dateExpired = document.getElementById(
        "date-of-expiry"
      ) as HTMLInputElement;
      dateIssued.value = "";
      dateExpired.value = "";
    }
  }
});
